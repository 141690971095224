import React, { useState } from 'react';
import logo from '../assets/images/logo.svg';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { UserManagementIcon } from '../assets/icons/userManagementIcon';
import { ShopListIcon } from '../assets/icons/shopListIcon';
import { DashboardIcon } from '../assets/icons/dashboardIcon';
import '../index.css';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { logout } from '../store/auth/action';
import { useDispatch } from 'react-redux';
import DropdownModal from './ConfirmationModal';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const Container = styled('div')({
  height: '100vh',
  overflowY: 'auto !important' as 'auto',
  minWidth: '220px',
  position: 'fixed',
  zIndex: '999',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  backgroundColor: '#F4F5FA',
  borderRight: '1px solid #e9e9e9;',
  '@media (max-width: 1024px)': {
    width: '150px',
    overflowY: 'auto !important' as 'auto',
  },
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(58, 53, 65, 0.87)',
  padding: '24px',
});

const ProfileLinkStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '9px 14px 9px 22px',
  marginBottom: '10px',
  color: 'black',
  fontSize: '18px',
};

const IconStyle = {
  margin: '10px 0px 0px',
  padding: '9px 14px 9px 0px ',
};

const Sidebar = () => {
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const role = useSelector((state: any) => state.auth.data.role);
  const user = useSelector((state: any) => state.auth.data);
  const users = useSelector((state: any) => state?.users);
  const currentLocation = useLocation().pathname;
  const menuData = [
    {
      id: 1,
      menuItem: 'Dashboard',
      icon: <DashboardIcon />,
      to: '/dashboard',
      role: [role],
    },
    {
      id: 2,
      menuItem: 'Shops',
      icon: <ShopListIcon />,
      to: '/shops',
      role: [1],
    },
    {
      id: 3,
      menuItem: 'Users',
      icon: <UserManagementIcon />,
      to: '/users',
      role: [2],
    },
    {
      id: 4,
      menuItem: 'Technicians',
      icon: <EngineeringOutlinedIcon />,
      to: '/technicians',
      role: [2, 3],
    },
    {
      id: 5,
      menuItem: 'Orders',
      icon: <ShoppingCartOutlinedIcon />,
      to: '/orders',
      role: [2, 3],
    },
    {
      id: 6,
      menuItem: 'Expenses',
      icon: <AccountBalanceWalletOutlinedIcon />,
      to: '/expenseList',
      role: [2, 3],
    },
    {
      id: 7,
      menuItem: 'Measurements',
      icon: <AccountBalanceWalletOutlinedIcon />,
      to: '/measurements',
      role: [2, 3],
    },
  ];

  const handleCloseLogout = () => {
    window.localStorage.removeItem('userToken');
    window.localStorage.removeItem('selectedFrequency');
    window.localStorage.removeItem('fromDate');
    window.localStorage.removeItem('toDate');
    dispatch(logout());
    navigator('/login');
  };

  const onClose = () => {
    setLogoutModal(false);
  };
  const handleLogoutModalYes = () => {
    handleCloseLogout();
    onClose();
  };

  return (
    <Container>
      <Box sx={{ mr: '20px' }}>
        <Title>
          <img rel='preload' src={logo} alt='' height={35} width={35} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant='h6'
              sx={{
                ml: 2,
                fontWeight: 900,
                color: 'rgba(58, 53, 65, 0.87)',
                fontSize: '22px',
                '@media (max-width: 1024px)': {
                  fontSize: '17px',
                },
              }}
            >
              STUDIO APP
            </Typography>
            <Typography
              variant='caption'
              sx={{
                ml: 2,
                mt: -1,
                fontWeight: 400,
                color: 'rgba(58, 53, 65, 0.87)',
                fontSize: '13px',
              }}
            >
              {user.role === 1
                ? 'Super Admin'
                : user.role === 2
                ? 'Shop Admin'
                : 'Shop Employee'}
            </Typography>
          </div>
        </Title>
        {menuData
          .filter((item) =>item.role.includes(role))
          .map((item) => (
            <Link to={item.to} key={item.id}>
              <Box
                className={`${
                  currentLocation.includes(item.to) ? 'tab active' : 'tab'
                }`}
                style={{ marginTop: '12px' }}
              >
                <Box
                  className={`${
                    currentLocation.includes(item.to) ? 'side-icons' : ''
                  }`}
                  sx={{ mr: '15px' }}
                >
                  {item.icon}
                </Box>
                <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>
                  {item.menuItem}
                </Typography>
              </Box>
            </Link>
          ))}
      </Box>
      <Box style={IconStyle}>
        {user.role !== 1 && (
          <Link
            to='/profile'
            style={ProfileLinkStyle}
            className={`${
              currentLocation === '/profile' ? 'active-profile' : 'tab'
            }`}
          >
            {users?.userData?.data?.profilePic ? (
              <img
                style={{
                  height: 20,
                  width: 20,
                  marginRight: 15,
                  borderRadius: 20,
                }}
                src={users?.userData?.data?.profilePic}
              />
            ) : (
              <PersonIcon
                style={{ marginRight: '10px' }}
                className={location.pathname === '/profile' ? 'white-icon' : ''}
              />
            )}
            {user.firstName + ' ' + user.lastName}
          </Link>
        )}
        <Link
          to=''
          style={ProfileLinkStyle}
          onClick={() => setLogoutModal(true)}
        >
          <LogoutIcon sx={{ marginRight: '10px' }} />
          Logout
        </Link>
      </Box>
      <DropdownModal
        title=' Are you sure you want to Logout ?'
        isOpen={logoutModal}
        handleClose={onClose}
        handleClickYes={handleLogoutModalYes}
      />
    </Container>
  );
};

export default Sidebar;
