import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Box, TableContainer, Paper } from '@mui/material';
import DropdownModal from '../../components/ConfirmationModal';
import { expenseStatusChange } from '../../store/expenses/action';
import { SelectChangeEvent } from '@mui/material/Select';
import NoDataImage from '../../components/NoDataImage';
import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { deleteMeasurement } from '../../store/mesurement/action';
import ViewMeasurementModal from './ViewMeasurementModal';
import PrintIcon from '@mui/icons-material/Print';
import { rowPerPage } from '../../utils';

const BASE_URL = process.env.REACT_APP_API_URL;

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px',
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
  fontSize: '16px',
  textWrap: 'nowrap',
}));

const TableDataWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  alignItems: 'center',
  height: '60%',
  width: '-webkit-fill-available',
});

const MeasurementListTable = ({
  measurementList,
  measurementListHeader,
  isAddModal,
  setPage,
  setIsModal,
  setEditedData,
  measurementDataLoader,
  page
}: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState('');
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState('');
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: '',
    item: '',
  });
  const [open, setOpen] = React.useState(false);
  const [viewModalData, setviewModalData] = React.useState();
  const deleteMeasurementloading = useSelector(
    (state: any) => state.deleteMeasurement?.loading
  );
  const handleOpen = (e: any, abc: any) => {
    setviewModalData(abc);
    setOpen(true);
  };
  const handleCloseMeasurement = () => setOpen(false);
  useEffect(() => {
    if (!deleteMeasurementloading) {
      setShowDeleteModal(false);
      setPage(1);
    }
  }, [!deleteMeasurementloading]);
  const dispatch = useDispatch();
  const searchData = useSelector((state: any) => state.expenseList);
  const searchLoading = useSelector(
    (state: any) => state?.expenseList?.loading
  );

  const editExpenseStatusLoader = useSelector(
    (state: any) => state.editExpenseStatus.loading
  );

  const handleOpenDeleteModal = (deleteId: string) => {
    setDeleteItemId(deleteId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (!editExpenseStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editExpenseStatusLoader]);

  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  };

  const handleChange = (event: SelectChangeEvent, item: any) => {
    let data = {
      expenseId: item.expenseId,
      technicianId: item.technicianId,
      status: event.target.value,
      description: item.description,
    };
    dispatch(expenseStatusChange(data));
  };

  const handleDeleteModalYes = () => {
    dispatch(
      deleteMeasurement({
        measurementId: deleteItemId,
        payload: {
          measurementId: deleteItemId,
        },
      })
    );
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };

  const navigateToEdit = (data: any) => {
    setEditedData(data);
    setIsModal(true);
  };

  function formatDate(dateString: any) {
    const inputDate = new Date(dateString);
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Note: Month is 0-indexed, so we add 1
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const printMeasurement = async (id: any) => {
    try {
      const getToken = () => {
        return window.localStorage.getItem('userToken') || '';
      };
      const token = getToken();
      const data = {
        measurementId: id,
      };
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(
        `${BASE_URL}/measurement/print`,
        data,
        config
      );
      if (response) {
        const printWindow = window.open('', '_blank');
        printWindow?.document.write(response.data);
        printWindow?.document.close();
        printWindow?.print();
      }
    } catch (error) {
    }
  };

  return (
    <>
      <Paper sx={{ overflow: 'hidden', mt: 2 }}>
        <StyledTableContainer>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              {measurementListHeader?.map((item: string, index: number) => (
                <StyledTableHeadCell key={index} align='center'>
                  {item}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchLoading || measurementDataLoader ? (
              <TableRow>
                <TableCell colSpan={measurementListHeader.length} align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : measurementList.measurementData?.data?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={measurementListHeader.length} align="center">
                  <NoDataImage />
                </TableCell>
              </TableRow>
            ) : (
              measurementList.measurementData?.data?.map((item: any, index: number) => (
                <TableRow hover key={index}>
                  <TableCell align='center'>{(page - 1) * rowPerPage + index + 1}</TableCell>
                  <TableCell align='center'>{item.customerName}</TableCell>
                  <TableCell align='center'>{item.customerNumber}</TableCell>
                  <TableCell align='center'>{formatDate(item.createdAt)}</TableCell>
                  <TableCell align='center'>
                    <EditButton navigateToEdit={() => navigateToEdit(item)} />
                    <DeleteButton handleOpenDeleteModal={() => handleOpenDeleteModal(item._id)} />
                    <VisibilityIcon sx={{ pl: '6px' }} onClick={(e: any) => handleOpen(e, item)} />
                    <PrintIcon sx={{ pl: '6px' }} onClick={() => printMeasurement(item._id)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
          
        </StyledTableContainer>
      </Paper>
      <DropdownModal
        loading={deleteMeasurementloading}
        title='Are you sure you want to Delete ?'
        isOpen={showDeleteModal}
        handleClose={handleClose}
        handleClickYes={handleDeleteModalYes}
      />
      <DropdownModal
        loading={editExpenseStatusLoader}
        title=' Are you sure you want to change the status?'
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={handleChange}
        dropdownModalValues={dropdownModalValues}
      />
      <ViewMeasurementModal
        open={open}
        handleClose={handleCloseMeasurement}
        measurement={viewModalData}
      />
    </>
  );
};

export default MeasurementListTable;
