import React, { useState } from "react";
import { TextField, Button, Box, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textColor: "black",
  },
  marginRight: "10px",
  width: "500px",
  zIndex: "500",
  backgroundColor: "white",
  fontSize: "16px",
  "@media  (max-width: 700px)": {
    width: "270px",
  },
  "@media screen and (min-width: 701px) and (max-width: 900px)": {
    width: "350px",
  },
}));

type SearchBarProps = {
  onSearch: (searchTerm: string) => void;
};

const SearchBar = ({ onSearch }: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const onClear = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <Box>
      <StyledTextField
        placeholder="Search here..."
        size="small"
        variant="outlined"
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm !== "" && (
                <CloseIcon
                  style={{
                    color: "rgba(58, 53, 65, 0.87)",
                    cursor: "pointer",
                  }}
                  onClick={onClear}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        onClick={handleSearch}
        disabled={searchTerm === ""}
        sx={{
          backgroundColor: searchTerm === "" ? "" : "#9155FD !important",
          size: "medium",
          borderRadius: "5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
          fontSize: "16px",
        }}
        color="primary"
      >
        Search
      </Button>
    </Box>
  );
};

export default SearchBar;
