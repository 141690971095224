import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  CircularProgress,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Paper, TableContainer } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PrintIcon from "@mui/icons-material/Print";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import DropdownModal from "../../components/ConfirmationModal";
import NoDataImage from "../../components/NoDataImage";
import { editOrderStatus, getOrder } from "../../store/order/action";
import { useSelector } from "react-redux";
import CompletedModal from "../../components/CompletedDialog";
import axios from "axios";
import { saveAs } from "file-saver";
import { formatIndianCurrency } from "../../utils/validations/Helpler";
import { rowPerPage } from "../../utils";

const BASE_URL = process.env.REACT_APP_API_URL;
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: "rgb(249, 250, 252)",
  color: "rgba(58, 53, 65, 0.87)",
  fontWeight: "bold",
  fontSize: "16px",
  textWrap: "nowrap",
  textAlign: "center",
  "@media (max-width: 1076px)": {
    fontSize: "16px",
    textWrap: "nowrap",
  },
}));

const TableDataWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  position: "absolute",
  alignItems: "center",
  height: "60%",
  width: "-webkit-fill-available",
});

const OrderListTable = ({
  orderData,
  orderDataLoader,
  orderListHeader,
  handleOpenDeleteModal,
  handleDeleteModalYes,
  handleClose,
  showDeleteModal,
  deleteLoading,
  page,
  sortDeliveryDate,
  sortedBy
}: any) => {
  const [sendInvoice, setSendInvoice] = useState<any>();
  const [delivered, setDelivered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: "",
    item: "",
  });
  const [template, setTemplate] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleCloseModel = () => {
    setAnchorEl(null);
  };
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState("");
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState("");
  const options = [
    { value: "placed", label: "Placed" },
    { value: "inProgress", label: "In Progress" },
    { value: "delayed", label: "Delayed" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "delivered", label: "delivered" },
  ];
  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId("");
    setDropdownConfirmationValue("");
    setShowDropdownConfirmation(false);
  };
  const handleClickItems = (e: any, item: any) => {
    setAnchorEl(e.currentTarget);
    setSendInvoice(item);
    setViewDetails(item);
  };

  const editOrderStatusLoader = useSelector(
    (state: any) => state.editOrderStatus.loading
  );

  useEffect(() => {
    if (!editOrderStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editOrderStatusLoader]);

  const handleChange = (event: SelectChangeEvent, item: any) => {
    setShowDropdownConfirmation(true);
    dispatch(
      editOrderStatus({ status: event.target.value, orderId: item._id })
    );
  };

  const handleDropdownConfirmationAction = (e: any, item: any) => {
    if (
      e.target.value === "delivered" ||
      Math.round(item.finalAmount - item.totalAdvancePayment) === 0
    ) {
      setDelivered(true);
    } else {
      setDropdownModalValues({ e, item });
      setShowDropdownConfirmation(true);
    }
  };

  const handleCloseCompleted = () => {
    setDelivered(false);
  };

  function formatDate(dateString: any) {
    const inputDate = new Date(dateString);
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-indexed, so we add 1
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const navigateToEdit = (data: any) => {
    navigate(`/order/detail/${data?._id}`, { state: {orderId: data?._id} });
  };
  const navigateToOrderDetails = () => {
    navigate(`/order/detail/${viewDetails?._id}`, { state: {orderId: viewDetails?._id} });
  };

  const handleDownload = async (backendUrl: any, filename: any) => {
    try {
      const response = await fetch(backendUrl);
      const blob = await response.blob();
      saveAs(blob, filename);
    } catch (error) {}
  };

  const sendReceipt = async () => {
    setLoading(true);
    try {
      const getToken = () => window.localStorage.getItem("userToken") || "";
      const token = getToken();

      const data = { orderId: sendInvoice._id };
      const config = { headers: { Authorization: `Bearer ${token}` } };

      const response = await axios.post(
        `${BASE_URL}/order/invoice/download`,
        data,
        {
          ...config,
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Invoice-${sendInvoice?.orderId}-${formatDate(sendInvoice?.createdAt)
          .split("/")
          .join("")}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the invoice:", error);

      alert("There was an error downloading the invoice. Please try again.");
    } finally {
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const printLetterhead = async () => {
    setPrintLoading(true);
    try {
      const getToken = () => {
        return window.localStorage.getItem("userToken") || "";
      };
      const token = getToken();
      const data = {
        orderId: sendInvoice._id,
      };
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "arraybuffer",
      };
      const response = await axios.post(
        `${BASE_URL}/order/letter/template`,
        data,
        // @ts-ignore
        config
      );
      if (response) {
        setTemplate(response.data);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        const printWindow = window.open(pdfUrl, "_blank");
        printWindow?.print();
      }
    } catch (error) {
      setPrintLoading(false);
      setAnchorEl(null);
    } finally {
      setPrintLoading(false);
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Paper sx={{ overflow: "hidden", mt: 2 }}>
        <StyledTableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {orderListHeader?.map((item: string, index: number) => (
                      item === "Delivery Date" ? (
                  <StyledTableHeadCell key={index} align="center">

                        <TableSortLabel
                          active={item === "Delivery Date"}
                          direction={
                            item === "Delivery Date" ? sortedBy : "asc"
                          }
                          onClick={() => sortDeliveryDate(sortedBy === "asc" ? "desc" : "asc")}
                        >
                          Delivery Date
                        </TableSortLabel>
                      </StyledTableHeadCell>
                      )
                      :
                  <StyledTableHeadCell key={index} align="center">
                    {item}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!orderDataLoader ? (
                orderData.length === 0 ? (
                  <TableRow>
                    <TableCell>
                      <NoDataImage />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow />
                )
              ) : (
                <TableDataWrapper>
                  <CircularProgress color="inherit" />
                </TableDataWrapper>
              )}
              {!orderDataLoader ? (
                orderData?.map((item: any, index: number) => (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      {(page - 1) * rowPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => console.log(item.orderId)}
                    >
                      {item.orderId.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">{item.customerName}</TableCell>
                    <TableCell align="center">{item.customerNumber}</TableCell>
                    <TableCell align="center">
                      {formatIndianCurrency(Math.round(item.finalAmount))}
                    </TableCell>
                    <TableCell align="center">
                      {formatIndianCurrency(
                        item.totalAdvancePayment > 0
                          ? Math.round(item.totalAdvancePayment)
                          : Math.round(item.advancePayment)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {formatIndianCurrency(
                        item.totalAdvancePayment > 0
                          ? Math.round(
                              item.finalAmount - item.totalAdvancePayment
                            )
                          : Math.round(item.finalAmount - item.advancePayment)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.deliveryDate)}
                    </TableCell>
                    <TableCell sx={{ align: "center", textAlign: "center" }}>
                      <FormControl
                        sx={{ m: 0, minWidth: 100, textAlign: "center" }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          sx={{
                            align: "center",
                            textAlign: "center",
                            minWidth: "130px",
                          }}
                          value={item.status}
                          label="status"
                          onChange={(e) =>
                            handleDropdownConfirmationAction(e, item)
                          }
                        >
                          {options.map((data: any, index: any) => (
                            <MenuItem
                              disabled={
                                Math.round(
                                  item.finalAmount - item.totalAdvancePayment
                                ) === 0
                                  ? true
                                  : false
                              }
                              value={data.value}
                              key={index}
                            >
                              {data.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", alignItems: "center" }}
                    >
                      <EditButton navigateToEdit={() => navigateToEdit(item)} />
                      <DeleteButton
                        handleOpenDeleteModal={() =>
                          handleOpenDeleteModal(item._id)
                        }
                      />
                      <IconButton
                        onClick={(e) => handleClickItems(e, item)}
                        style={{
                          marginTop: "-15px",
                          paddingLeft: "6px",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseModel}
                        PaperProps={{
                          sx: {
                            boxSizing: "border-box",
                            transition:
                              "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                            mt: 0.5,
                            "& .MuiAvatar-root": {
                              width: 5,
                              height: 5,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 5,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "center",
                            borderRadius: "4px",
                          }}
                        >
                          <MenuItem
                            sx={{
                              borderBottom: "0.5px solid rgba(224,224,224,1)",
                              textAlign: "center",
                            }}
                            onClick={navigateToOrderDetails}
                          >
                            <VisibilityIcon />
                            &nbsp; View Details
                          </MenuItem>
                          {/* {loading ? (
                            <Button fullWidth size="large" type="submit">
                              <CircularProgress
                                style={{
                                  color: '#9155FD',
                                  height: '25px',
                                  width: '25px',
                                }}
                              />
                            </Button>
                          ) : (
                            <MenuItem
                              sx={{
                                borderBottom: '0.5px solid rgba(224,224,224,1)',
                                textAlign: 'center',
                              }}
                              onClick={sendReceipt}
                            >
                              <DownloadIcon />
                              &nbsp; Download Invoice
                            </MenuItem>
                          )} */}
                          <MenuItem onClick={printLetterhead}>
                            {printLoading ? (
                              <CircularProgress
                                size={15}
                                sx={{ mr: 1, color: "#9155FD" }}
                              />
                            ) : (
                              <PrintIcon />
                            )}
                            &nbsp; Print Letterhead
                          </MenuItem>
                          <MenuItem onClick={sendReceipt}>
                            {loading ? (
                              <CircularProgress
                                size={15}
                                sx={{ mr: 1, color: "#9155FD" }}
                              />
                            ) : (
                              <DownloadIcon sx={{ mr: 1 }} />
                            )}
                            &nbsp; Download PDF
                          </MenuItem>
                        </Box>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableDataWrapper />
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
      <DropdownModal
        loading={editOrderStatusLoader}
        title=" Are you sure you want to change the status?"
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={handleChange}
        dropdownModalValues={dropdownModalValues}
      />
      <DropdownModal
        loading={deleteLoading}
        title=" Are you sure you want to Delete ?"
        isOpen={showDeleteModal}
        handleClose={handleClose}
        handleClickYes={handleDeleteModalYes}
      />
      <CompletedModal
        isOpen={delivered}
        handleClose={handleCloseCompleted}
        title="Payment Remaining"
        message="Please complete the payment first!"
      />
    </>
  );
};

export default OrderListTable;
