import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { history } from '../../routes';
import { loginUser, forgotPassword, resetPassword } from '../../services';
import {
  ADD_LOGIN_FAILURE,
  ADD_LOGIN_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  IS_LOADING,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS
} from '../constant';
import customHistory from '../../routes/history';

export function* loginUserSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true
    });
    const response = yield call(loginUser, action.payload);
    yield put({
      type: ADD_LOGIN_SUCCESS,
      payload: response.data.data
    });
    localStorage.setItem('userId', response.data.data?.userId);
    localStorage.setItem('userToken', response.data.data?.token);
    localStorage.setItem('shopId', response.data.data?._id);
    toast.success(response.data.message);
    
    yield put({
      type: IS_LOADING,
      payload: false
    });

    history.push('/reset/password');
  } catch (error: any) {
    yield put({
      type: ADD_LOGIN_FAILURE,
      payload: error.response?.data?.message || 'Something went wrong'
    });
    
    toast.error(error.response?.data?.message || 'Something went wrong');

    // Ensure loading is stopped on failure
    yield put({
      type: IS_LOADING,
      payload: false
    });
  }
}


export function* forgotPasswordSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true
    });
    const response = yield call(forgotPassword, action.payload);
    toast.success(response.data.message);
    yield put({
      type: IS_LOADING,
      payload: false
    });
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: response.data.data
    });
    customHistory.push('/login');
  } catch (error: any) {
    yield put({
      type: FORGOT_PASSWORD_FAILURE,
      payload: false
    });
    toast.error(error.response.data.message);
  }
}

export function* resetPasswordSaga(action: any): any {
  try {
    const response = yield call(resetPassword, action.payload);
    toast.success(response.data.message);
    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: response.data.data
    });
    customHistory.push('/login');
  } catch (error: any) {
    yield put({
      type: RESET_PASSWORD_FAILURE,
      payload: false
    });
    toast.error(error.response.data.message);
  }
}
