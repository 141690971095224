import {
  DELETE_ORDER_BEGIN,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  IS_LOADING,
} from "../constant";

const initialState: any = {
  loading: false,
  deleteOrderData: [],
  error: null,
  isLoading: false,
};

const deleteOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DELETE_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case DELETE_ORDER_SUCCESS:
      return { ...state, loading: false, deleteOrderData: action.payload, error: null };

    case DELETE_ORDER_FAILURE:
      return { ...state, error: action.payload, loading: false, };
    case IS_LOADING:
      return {
        ...state,
        loading: false,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default deleteOrderReducer;
