import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrder, getOrder } from "../../store/order/action";
import { Pagination } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import { CircularProgress } from "@mui/material";
import SearchBar from "../../components/SearchBar";
import FilterModal from "../../components/FilterModal";
import OrderListTable from "./TableItem";

const Container = styled(Box)({
  backgroundColor: "#F4F5FA",
  width: "100%",
  height: "100vh",
});

const WrapperItem = styled(Box)({
  padding: "20px 20px 20px 270px",
  backgroundColor: "#F4F5FA",
});

const SearchBarWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
  "@media (max-width: 1076px)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "10px",
    justifyContent: "space-between",
  },
});

const PaginationWrapper = styled(Box)({
  display: "flex",
  justifyContent: "right",
  marginTop: "12px",
  fontWeight: "bold",
});

const orderListHeader = [
  "No.",
  "Order ID",
  "Name",
  "Phone Number",
  "Amount (₹)",
  "Paid (₹)",
  "Remaining (₹)",
  "Delivery Date",
  "Status",
  "Action",
];

const OrderList = () => {
  const [page, setPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [orderList, setorderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filters, setFilters] = useState({
    searchTerm: "",
    status: "",
    paymentMode: "",
    fromDate: "",
    toDate: "",
    sortOrder: "asc"
  });

  const dispatch = useDispatch();
  const orderDataLoader = useSelector((state: any) => state.order.loading);
  const orderDataPagination = useSelector(
    (state: any) => state.order?.orderListData
  );
  const auth = useSelector((state: any) => state.auth);
  const orders = useSelector((state: any) => state?.order);
  const searchData = useSelector((state: any) => state.order.data.data);
  const count = Math.ceil(orderDataPagination?.total / 10);

  const handleChange = (event: any, value: any) => {
    fetchOrders(value);
  };

  const getOrderList = (searchTerm: string): any => {
    setFilters((prev) => ({ ...prev, searchTerm }));
  };

  useEffect(() => {
    fetchOrders(1, filters);
  }, [filters]);

  useEffect(() => {
    if (orders?.orderListData?.data) {
      setorderList(orders?.orderListData?.data);
    }
  }, [orders?.orderListData?.data]);

  const handleDeleteModalYes = () => {
    dispatch(
      deleteOrder({
        payload: {
          id: deleteItemId,
          authId: auth.data._id,
        },
      })
    );
  };

  const deleteOrderLoader = useSelector((state: any) => state.deleteOrderData.loading);

  useEffect(() => {
    if (!deleteOrderLoader) {
      setShowDeleteModal(false);
      setPage(1);
    }
  }, [!deleteOrderLoader]);

  const handleOpenDeleteModal = (deleteId: string) => {
    setDeleteItemId(deleteId);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId("");
  };

  const handleFilter = (filterData: any) => {
    setFilters(prev => {
      const updatedFilters = { 
        ...prev, 
        ...filterData 
      };
      return updatedFilters;
    });
  };

  const handleSortingDeliveryDate = (sortOrder: any) => {
    setFilters(prev => {
      const updatedFilters = { 
        ...prev, 
        sortOrder 
      };
      return updatedFilters;
    });
  };

  const fetchOrders = (pageNumber: number, updatedFilters = filters) => {
    dispatch(
      getOrder({
        id: auth.data._id,
        page: pageNumber,
        searchTerm: updatedFilters.searchTerm,
        rowsPerPage: 10,
        status: updatedFilters.status,
        paymentMode: updatedFilters.paymentMode,
        sortOrder: updatedFilters.sortOrder,
        fromDate: updatedFilters.fromDate
          ? updatedFilters.fromDate.toString()
          : "",
        toDate: updatedFilters.toDate ? updatedFilters.toDate.toString() : "",
      })
    );
    setPage(pageNumber);
  };

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant="h5" sx={{ color: "#9155FD", fontWeight: 600 }}>
          Orders List
        </Typography>
        <SearchBarWrapper>
          <SearchBar onSearch={getOrderList} />
          <Box sx={{ display: "flex" }}>
            <FilterModal parentCallback={handleFilter} />
            <Link to="/order/add">
              <Button
                size="medium"
                variant="contained"
                sx={{
                  fontSize: "16px",
                  marginLeft: "10px",
                  backgroundColor: "#9155FD !important",
                  "@media (max-width: 1076px)": { marginTop: "10px" },
                }}
              >
                Create
              </Button>
            </Link>
          </Box>
        </SearchBarWrapper>
        <OrderListTable
          orderData={orderList}
          orderListHeader={orderListHeader}
          orderDataLoader={orderDataLoader}
          showDeleteModal={showDeleteModal}
          handleClose={handleClose}
          handleDeleteModalYes={handleDeleteModalYes}
          handleOpenDeleteModal={handleOpenDeleteModal}
          deleteLoading={deleteOrderLoader}
          page={page}
          sortDeliveryDate={handleSortingDeliveryDate}
          sortedBy={filters.sortOrder}
        />
        {searchData?.loading && <CircularProgress /> && "No Data Found"}
        <PaginationWrapper>
          <Pagination
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#9155FD !important",
                color: "white",
              },
              "& .Mui-selected:hover": {
                backgroundColor: "#9155FD !important",
                color: "white",
              },
            }}
            count={count}
            page={page}
            onChange={handleChange}
          />
        </PaginationWrapper>
      </WrapperItem>
    </Container>
  );
};

export default OrderList;
