import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Edit from './Edit';
import { styled } from '@mui/material/styles';

const AddOrderDetailsView = styled(Box)(({ theme }) => ({
  position: 'relative' as 'relative',
  marginTop: '10px',
  backgroundColor: 'white',
  border: '2px solid #FFFFFF !important',
  borderRadius: theme.spacing(1),
  padding: '30px 30px 30px 30px',
  height: 'auto !important',
  '@media (max-width: 1200px)': {
    height: '30% !important',
    padding: '30px 30px 70px 30px',
      },

}));

const CustomButton = styled(Button)({
  backgroundColor: '#9155FD !important',
  '@media (max-width: 1024px)': {
   marginLeft:'20px'
      },
});

const ButtonWrapper = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 0,
  marginTop: '0px',
  padding: '20px',
  '@media (max-width: 767px)': {
    left:0,
  marginTop: '200px',
     },
'@media (max-width: 1200px)': {
    left:0,
  marginTop: '130px',
     },
});

const View = () => {
  const [openEditform, setOpenEditForm] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [editedValues, setEditedValues] = useState<any>();

  const orderEditData = useSelector((state: any) => state?.orderDetail?.orderDetailData);
  const EditOrderDetails = useSelector((state: any) => state?.editcustomerOrderDetails);
  const isSuccess = useSelector(
    (state: any) => state?.editcustomerOrderDetails?.isSuccess
  );
  let edited = false

  const updateData = () => {
    setUpdatedData(EditOrderDetails);
    setOpenEditForm(true);
    edited = true
  };

  useEffect(() => {
    if (isSuccess) {
      setOpenEditForm(false);
    }
  }, [isSuccess]);

  const emailData = () => {
    if (orderEditData.customerEmail === '') {
      return 'NA';
    } else {
      return editedValues
        ? editedValues.customerEmail
        : orderEditData?.customerEmail;
    }
  }

  return (
    <>
      {openEditform ? (
        <Edit
          setOpenEditForm={setOpenEditForm}
          setEditedValues={setEditedValues}
          editedValues={editedValues}
          orderEditData={orderEditData}
          updatedData={updatedData}
          edited={edited}
        />
      ) : (
        <AddOrderDetailsView >
          <Grid
            container
            rowGap={2}
          >
              <Grid item md={6} lg={4} sm={6} xs={12} container alignItems={'center'}>
                <Grid item md={4} lg={3}>
                  <Typography display='inline' sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                    Name :
                  </Typography>
                </Grid>
              <Grid item md={8} lg={9}alignItems={'center'}>
                {editedValues
                  ? editedValues.customerName
                  : orderEditData?.customerName}
              </Grid>
              </Grid>
            <Grid item md={6} lg={4} sm={6} xs={12}  container alignItems={'center'}>
              <Grid item sm={3} md={3} lg={4} textAlign={'start'}>
                <Typography display='inline' sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                  Email :
                </Typography>
              </Grid>
              <Grid item sm={9} md={9}  lg={8}  sx={{textWrap: 'nowrap'
              }} alignItems={'center'}>
              {emailData()}
              
                
              </Grid>
            </Grid>
            <Grid item md={6} lg={4} sm={6} xs={12} container alignItems={'center'}>
              <Grid item md={4} lg={4}>
                <Typography display='inline' sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                  Contact :
                </Typography>
              </Grid>
              <Grid item md={8} lg={8} alignItems={'center'}>
                {editedValues
                  ? editedValues.customerNumber
                  : orderEditData?.customerNumber}
              </Grid>
            </Grid>
            <Grid item md={6} lg={4} sm={6} xs={12} container alignItems={'center'}>
              <Grid item md={3} sm={4}>
                <Typography display='inline' sx={{ fontWeight: 'bold',textWrap: 'nowrap'  }}>
                  Address :
                </Typography>
              </Grid>
              <Grid item md={9} sm={8} alignItems={'center'} sx={{textWrap: 'nowrap'  }}>
                {editedValues
                  ? editedValues.customerAddress
                  : orderEditData?.customerAddress}
              </Grid>
            </Grid>
            <Grid item md={6} lg={4} sm={6} xs={12} container alignItems={'center'}>
              <Grid item md={3}  lg={4} >
                <Typography display='inline' sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                 Order Date :
                </Typography>
              </Grid>
              <Grid item md={9}  lg={8} alignItems={'center'}>
                {editedValues
                  ? dayjs(editedValues.orderDate).format('DD/MM/YYYY')
                  : dayjs(orderEditData?.orderDate).format('DD/MM/YYYY')}
              </Grid>
            </Grid>
            <Grid item md={6} lg={4}  sm={6} xs={12}  container alignItems={'center'}>
              <Grid item md={4} lg={4} textAlign={'start'}>
                <Typography display='inline' sx={{ fontWeight: 'bold', textWrap: 'nowrap' }}>
                  Delivery Date:
                </Typography>
              </Grid>
              <Grid item md={8} lg={8} alignItems={'center'}>
                {editedValues
                  ? dayjs(editedValues.deliveryDate).format('DD/MM/YYYY')
                  : dayjs(orderEditData?.deliveryDate).format('DD/MM/YYYY')}
              </Grid>
            </Grid>  
          </Grid>
          <ButtonWrapper>
          <CustomButton variant='contained' onClick={updateData}>
            Edit
          </CustomButton>
          </ButtonWrapper>
        </AddOrderDetailsView>
      )}
    </>
  );
};
export default View;