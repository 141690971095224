import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DashboardCard from "../components/DashboardCard";
import LineChart from "../components/LineChart";
import PeiChart from "../components/PeiChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addDashboard } from "../store/users/action";
import expense from "../assets/images/expenses.svg";
import order from "../assets/images/checkout-icon.svg";
import technician from "../assets/images/construction-engineer-icon.svg";
import MenuItem from "@mui/material/MenuItem";
import user from "../assets/images/user.svg";
import revenue from "../assets/images/revenue.svg";
import {
  Backdrop,
  Button,
  Grid,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import NoDataImage from "../components/NoDataImage";
import DatePickers from "../components/DatePickers";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { monthLabel } from "../utils/monthLabel";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { formatIndianCurrency } from "../../src/utils/validations/Helpler";

const Container = styled(Box)({
  backgroundColor: "#F4F5FA",
  width: "100%",
  height: "100vh",
});

const StyledMainContainer = styled(Box)({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  height: "65vh",
  display: "flex",
  flexDirection: "column",
  boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
  width: "100%",
  boxSizing: "border-box",
  borderRadius: "6px",
  backgroundColor: "white",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
});

const WrapperItem = styled(Box)({
  padding: "20px 20px 20px 270px",
  backgroundColor: "#F4F5FA",
});

const StyledTableContainer = styled("div")({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
  width: "70%",
  paddingBottom: "10px",
  boxSizing: "border-box",
  borderRadius: "6px",
  backgroundColor: "white",
  marginRight: "15px",
  "@media (max-width:1024px)": {
    marginTop: "10px",
    height: "auto",
    width: "100%",
    paddingBottom: "20px",
  },

});
// const StyledContainer = styled("div")({
//   display: "flex",
//   flexDirection: "row",
// });

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between", // Ensure equal spacing
  width: "100%",
});

const StyledTable = styled("div")({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  height: "70vh",
  display: "flex",
  flexDirection: "column",
  boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
  width: "100%",
  boxSizing: "border-box",
  borderRadius: "6px",
  backgroundColor: "white",
  overflowY: "auto !important" as "auto",
  "@media (max-width:1024px)": {
    overflowY: "auto",
    width: "100%",
  },
});

const StyledPieContainer = styled("div")({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  borderRadius: "6px",
  boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
  width: "30%",
  backgroundColor: "white",
  "@media (max-width:1024px)": {
    marginTop: "10px",
    width: "100%",
    paddingBottom: "20px",
  },
});

// const StyledUpcomingContainer = styled("div")({
//   transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
//   display: "flex",
//   flexDirection: "row",
//   height: "auto",
//   borderRadius: "6px",
//   boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
//   width: "50%",
//   marginTop: "20px",
//   backgroundColor: "white",
//   overflow: "scroll",
//   "@media (max-width:1024px)": {
//     marginTop: "10px",
//     width: "100%",
//     paddingBottom: "20px",
//   },
// });
const StyledUpcomingContainer = styled("div")({
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  borderRadius: "6px",
  boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.2)",
  width: "50%",  // Change from 48% to 50% for equal width
  marginTop: "20px",
  backgroundColor: "white",
  overflow: "scroll",
  "@media (max-width:1024px)": {
    marginTop: "10px",
    width: "100%",
    paddingBottom: "20px",
  },
});

const ChartBox = styled(Box)({
  display: "flex",
  marginTop: "20px",
  paddingRight: "20px",
  justifyContent: "space-between",
  "@media (max-width:1024px)": {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "space-between",
  },
});
const ChartOrder = styled(Box)({
  display: "flex",
  marginTop: "20px",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  "@media (max-width:1024px)": {
    flexDirection: "column",
    marginTop: "10px",
    justifyContent: "space-between",
  },
});

const OrderLabel = styled(Box)({
  paddingLeft: "15px",
  textAlign: "center",
  border: "1px solid lightGray",
  padding: "10px",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  cursor: "pointer",
  color: "rgba(58, 53, 65, 0.87)",
});

const TableLabel = styled(Box)({
  paddingLeft: "15px",
  textAlign: "left",
  alignItems: "left",
  justifyContent: "left",
  alignSelf: "left",
  cursor: "pointer",
  marginTop: "10px",
  marginBottom: "5px",
  color: "rgba(58, 53, 65, 0.87)",
});

const CustomLabel = styled(Box)({
  paddingLeft: "15px",
  textAlign: "center",
  border: "1px solid lightGray",
  padding: "10px",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  cursor: "pointer",
  color: "rgba(58, 53, 65, 0.87)",
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px",
});

const OrderTitle = styled(Box)({
  padding: "15px",
  borderBottom: "1px solid lightGray",
  fontWeight: "bold",
  background: "rgb(249, 250, 252)",
  color: "rgba(58, 53, 65, 0.87)",
});

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: "rgb(249, 250, 252)",
  color: "rgba(58, 53, 65, 0.87)",
  fontWeight: "bold",
  fontSize: "16px",
  textWrap: "nowrap",
}));

const TableLabelStyle = styled(TableLabel)({
  fontWeight: "bold",
  color: "#7149C6",
  fontSize: "20px",
});

const orderHeader = {
  all: [
    "id",
    "Name",
    "Phone Number",
    "Amount (₹)",
    "Advance (₹)",
    "Remaining (₹)",
    "Delivery Date",
    "Status",
  ],
  "Upcoming Orders": ["Name", "Phone Number", "Delivery Date", "Status"],
  "Payment Orders": ["Name", "Amount (₹)", "Remaining (₹)", "Delivery Date", "Status"],
  "Due Orders": ["Name", "Phone Number", "Amount (₹)", "Remaining (₹)", "Delivery Date", "Status"],
};

const lists = [
  { id: 1, title: "Daily", value: "daily" },
  { id: 2, title: "Weekly", value: "weekly" },
  { id: 3, title: "Monthly", value: "monthly" },
  { id: 4, title: "yearly", value: "yearly" },
];

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let FromDate = localStorage.getItem("fromDate");
  let Todate = localStorage.getItem("toDate");
  let frequencyValue = localStorage.getItem("selectedFrequency");
  const selectedFrequencyId = (frequencyValue: any) => {
    if (frequencyValue === null) {
      return 1;
    }
    if (frequencyValue === "yearly") {
      return 4;
    }
    if (frequencyValue === "monthly") {
      return 3;
    }
    if (frequencyValue === "weekly") {
      return 2;
    }
    if (frequencyValue === "daily") {
      return 1;
    }
  };
  const [selectedFrequency, setSelectedFrequency] = useState(
    frequencyValue === null ? "daily" : frequencyValue
  );
  const [selected, setSelected] = useState<any>(
    selectedFrequencyId(frequencyValue)
  );
  const [placed, setPlaced] = useState();
  const [completed, setCompleted] = useState();
  const [inProgress, setInProgress] = useState();
  const [delay, setDelay] = useState();
  const [cancel, setCancel] = useState();
  const [active, setActive] = useState(FromDate && Todate ? true : false);
  const [fromDate, setfromDate] = React.useState<any>(FromDate);
  const [toDate, setToDate] = React.useState<any>(Todate);
  const [openDaysItems, setOpenDaysItems] = React.useState<null | HTMLElement>(
    null
  );
  const [selectedCustom, setSelectedCustom] = useState(false);

  const dashboardData = useSelector(
    (state: any) => state.dashboardDetails.dashboardData
  );

  const dashboardLoading = useSelector(
    (state: any) => state.dashboardDetails.loading
  );

  const handledashboardStatus = (row: any) => {
    localStorage.removeItem("fromDate");
    localStorage.removeItem("toDate");
    localStorage.setItem("selectedFrequency", row.value);
    setSelectedFrequency(row.value);
    setSelectedCustom(false);
    setSelected(row.id);
    dispatch(addDashboard({ frequency: row.value }));
    setActive(false);
    setfromDate(null);
    setToDate(null);
  };

  const role = useSelector((state: any) => state.auth.data.role);
  const dashboardLoader = useSelector(
    (state: any) => state.dashboardDetails.loading
  );

  const Labels = (selectedFrequency: any) => {
    if (selectedFrequency === "yearly") {
      return dashboardData.orders?.map((item: any) => item?._id);
    }
    if (selectedFrequency === "monthly") {
      return dashboardData.orders?.map((item: any) =>
        monthLabel(item?._id.month)
      );
    }
    if (selectedFrequency === "weekly") {
      return dashboardData.orders?.map((item: any) => "week-" + item?._id);
    }
    if (selectedFrequency === "daily") {
      return dashboardData.orders?.map((item: any) => item?._id);
    }
  };

  const StatusLabelColor = (status: any) => {
    if (status === "placed") {
      return "#141E46";
    }
    if (status === "completed") {
      return "#005B41";
    }
    if (status === "inProgress") {
      return "#E48F45";
    }
    if (status === "delayed") {
      return "#FF6969";
    }
    if (status === "cancelled") {
      return "#C70039";
    }
  };

  const LineChartData = {
    labels: Labels(selectedFrequency),
    datasets: [
      {
        label: "orders",
        data: dashboardData.orders?.map((item: any) => item?.totalOrdersAdded),
        borderColor: "#7149C6",
        backgroundColor: "#7149C6",
      },
      {
        label: "Expenses",
        data: dashboardData.expenses?.map((item: any) => item?.totalExpenses),
        borderColor: "#C70039",
        backgroundColor: "#C70039",
      },
    ],
  };

  const PieChartdata = {
    datasets: [
      {
        data: [completed, placed, inProgress, delay, cancel],
        backgroundColor: [
          "#005B41",
          "#141E46",
          "#FFF5E0",
          "#FF6969",
          " #C70039",
        ],
      },
    ],
    labels: ["Completed", "Placed", "Inprogress", "Delayed", "Cancelled"],
  };

  const handleClickItems = (e: any) => {
    setOpenDaysItems(e.currentTarget);
    setSelectedCustom(true);
    setSelected(0);
  };

  const handleClose = () => {
    setOpenDaysItems(null);
    setfromDate(null);
    setToDate(null);
  };

  const handleClickApply = () => {
    localStorage.removeItem("selectedFrequency");
    dispatch(
      addDashboard({ frequency: "custom", fromDate: fromDate, toDate: toDate })
    );
    setOpenDaysItems(null);

    if (!dashboardLoader) {
      setActive(true);
      localStorage.setItem("fromDate", fromDate);
      localStorage.setItem("toDate", toDate);
    }
  };

  useEffect(() => {
    if (dashboardData.totalOrders !== 0) {
      if (dashboardData?.orders?.length === 0) {
        toast("Order data not found");
      }
    }
  }, [dashboardData?.orders?.length]);

  useEffect(() => {
    if (FromDate && Todate) {
      setSelected(0);
      setSelectedFrequency("custom");
      setSelectedCustom(true);
    }
    if (dashboardData?.length === 0) {
      dispatch(addDashboard({ frequency: selectedFrequency }));
    }
  }, [selectedFrequency]);

  useEffect(() => {
    if (!dashboardLoader) {
      const placed = dashboardData?.orderStatus?.filter(
        (data: any) => data.status === "placed"
      );

      if (placed?.length > 0) {
        setPlaced(placed[0].totalOrders);
      }
      const completed = dashboardData?.orderStatus?.filter(
        (data: any) => data.status === "completed"
      );
      if (completed?.length > 0) {
        setCompleted(completed[0].totalOrders);
      }

      const inProgress = dashboardData?.orderStatus?.filter(
        (data: any) => data.status === "inProgress"
      );
      if (inProgress?.length > 0) {
        setInProgress(inProgress[0].totalOrders);
      }

      const delayed = dashboardData?.orderStatus?.filter(
        (data: any) => data.status === "delayed"
      );
      if (delayed?.length > 0) {
        setDelay(delayed[0].totalOrders);
      }

      const cancel = dashboardData?.orderStatus?.filter(
        (data: any) => data.status === "cancelled"
      );
      if (cancel?.length > 0) {
        setCancel(cancel[0].totalOrders);
      }
    }
  }, [!dashboardLoader]);

  const navToEdit = (data: any) => {
    navigate(`/order/detail/${data?._id}`, { state: { orderId: data?._id } });
  };

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        {role !== 1 && (
          <Box>
            <Grid item container spacing={2} md={12}>
              <Grid item xs={12} sm={6} md={4} lg={2.38}>
                <DashboardCard
                  onClick={() => navigate("/users")}
                  type="Users"
                  count={dashboardData?.totalEmployees}
                  image={user}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.38}>
                <DashboardCard
                  onClick={() => navigate("/technicians")}
                  type="Technicians"
                  count={dashboardData?.totalTechnicians}
                  image={technician}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.38}>
                <DashboardCard
                  onClick={() => navigate("/orders")}
                  type="Orders"
                  count={dashboardData?.totalOrders}
                  image={order}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.38}>
                <DashboardCard
                  onClick={() => navigate("/expenseList")}
                  type="Expenses"
                  count={formatIndianCurrency(dashboardData?.totalExpenses)}
                  image={expense}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.38}>
                <DashboardCard
                  type="Revenue"
                  count={formatIndianCurrency(dashboardData?.totalRevenue)}
                  image={revenue}
                />
              </Grid>
            </Grid>
            <ChartBox>
              {dashboardData?.totalOrders === 0 ? (
                <StyledMainContainer>
                  <NoDataImage />
                </StyledMainContainer>
              ) : (
                <>
                  <Backdrop
                    sx={{
                      color: "black",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={dashboardLoader}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <StyledTableContainer>
                      <div style={{ display: "flex" }}>
                        <CustomLabel
                          sx={{
                            bgcolor: "white",
                            "&:hover": {
                              bgcolor: "#F4F5FA",
                            },
                          }}
                          style={{
                            backgroundColor: selectedCustom ? "#F4F5FA" : "",
                            color: selectedCustom ? "#7149C6" : "",
                          }}
                          onClick={(e) => handleClickItems(e)}
                        >
                          <div
                            style={{
                              display: "flex",
                              height: 22,
                              marginTop: -3,
                            }}
                          >
                            <DateRangeIcon
                              style={{
                                color: selectedCustom ? "#7149C6" : "",
                              }}
                            />
                            <span style={{ marginTop: 4, marginLeft: 5 }}>
                              {!active
                                ? "Custom"
                                : dayjs(fromDate).format("DD/MM/YYYY") +
                                  " - " +
                                  dayjs(toDate).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        </CustomLabel>
                        <Menu
                          sx={{ mt: 6 }}
                          anchorEl={openDaysItems}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          open={Boolean(openDaysItems)}
                        >
                          <Box
                            sx={{
                              boxShadow: "rgba(0, 0, 0, 0.2)",
                              textAlign: "center",
                              borderRadius: "4px",
                            }}
                          >
                            <MenuItem
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <DatePickers
                                  style={{ width: 150 }}
                                  placeholder={"From Date"}
                                  value={fromDate}
                                  onChange={(date: any) => setfromDate(date)}
                                />
                                <span style={{ marginLeft: 10 }} />
                                <DatePickers
                                  style={{ width: 150 }}
                                  minDate={fromDate}
                                  placeholder={"To Date"}
                                  value={toDate}
                                  onChange={(date: any) => setToDate(date)}
                                />
                              </div>
                            </MenuItem>

                            {!dashboardLoading ? (
                              <Button
                                disabled={
                                  fromDate === null || toDate === null
                                    ? true
                                    : false
                                }
                                variant="contained"
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                  backgroundColor:
                                    fromDate === null || toDate === null
                                      ? "gray"
                                      : "#7149C6",
                                  color: "white",
                                }}
                                color="primary"
                                onClick={handleClickApply}
                              >
                                Apply
                              </Button>
                            ) : (
                              <Button size="small" type="submit">
                                <CircularProgress
                                  style={{
                                    color: "#9155FD",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                />
                              </Button>
                            )}
                            <Button
                              sx={{
                                ml: 1,
                                borderRadius: "5px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                              }}
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Menu>
                        {lists.map((list: any, index: number) => {
                          return (
                            <OrderLabel
                              style={{
                                backgroundColor:
                                  list.id === selected ? "#F4F5FA" : "",
                                color: list.id === selected ? "#7149C6" : "",
                                borderTopRightRadius: index === 3 ? "10px" : "",
                                borderBottomRightRadius:
                                  index === 3 ? "10px" : "",
                              }}
                              onClick={() => handledashboardStatus(list)}
                              sx={{
                                bgcolor: "white",
                                "&:hover": {
                                  bgcolor: "#F4F5FA",
                                },
                              }}
                              key={index}
                            >
                              {list.title}
                            </OrderLabel>
                          );
                        })}
                      </div>

                    <div
                      style={{
                        alignSelf: "center",
                        margin: "auto",
                        height: "auto",
                        position: "relative",
                      }}
                    >
                      {!dashboardLoading && <LineChart data={LineChartData} />}
                    </div>
                  </StyledTableContainer>
                  <StyledPieContainer>
                    <OrderTitle>Order Status</OrderTitle>
                    <div
                      style={{
                        alignSelf: "center",
                        position: "relative",
                        height: "auto",
                      }}
                    >
                      <PeiChart data={PieChartdata} />
                    </div>
                  </StyledPieContainer>
                </>
              )}
            </ChartBox>
          </Box>
        )}
        <StyledContainer>
          <StyledUpcomingContainer>
            {dashboardData?.upcomingOrders?.length > 0 && (
                  <ChartOrder>
                      <TableLabelStyle>Upcoming Orders</TableLabelStyle>
                      <Table
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          width: "96%",
                          marginTop: "10px",
                          marginBottom: "20px",
                          marginLeft: "10px"
                        }}
                        stickyHeader
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            {orderHeader["Upcoming Orders"]?.map(
                              (item: string, index: number) => (
                                <StyledTableHeadCell key={index} align="center">
                                  {item}
                                </StyledTableHeadCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardData?.upcomingOrders?.slice(0, 5)?.map(
                            (item: any, index: number) => (
                              <TableRow hover>
                                <TableCell
                                  align="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navToEdit(item)}
                                >
                                  {item.customerName}
                                </TableCell>
                                <TableCell align="center">
                                  {item.customerNumber}
                                </TableCell>
                                <TableCell align="center">
                                  {dayjs(item?.deliveryDate).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: StatusLabelColor(item.status),
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.status}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                  </ChartOrder>
                )}
          </StyledUpcomingContainer>

          <StyledUpcomingContainer style={{marginLeft: 10}}>
              {dashboardData?.remainingAmountOrders?.length > 0 && (
                <ChartOrder>
                    <TableLabelStyle>Remaining Payment Orders</TableLabelStyle>
                    <Table
                      style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          width: "96%",
                          marginTop: "10px",
                          marginBottom: "20px",
                          marginLeft: "10px",
                      }}
                      stickyHeader
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          {orderHeader["Payment Orders"]?.map(
                            (item: string, index: number) => (
                              <StyledTableHeadCell key={index} align="center">
                                {item}
                              </StyledTableHeadCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashboardData?.remainingAmountOrders?.map(
                          (item: any, index: number) => (
                            <TableRow hover>
                              <TableCell
                                align="center"
                                style={{ cursor: "pointer" }}
                                onClick={() => navToEdit(item)}
                              >
                                {item.customerName}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                {Math.round(item.finalAmount)}
                              </TableCell>
                              <TableCell align="center">
                                {Math.round(
                                  item.finalAmount - item.advancePayment
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {dayjs(item?.deliveryDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: StatusLabelColor(item.status),
                                  fontWeight: "bold",
                                }}
                              >
                                {item.status}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                </ChartOrder>
              )}
          </StyledUpcomingContainer>
        </StyledContainer>

          <StyledUpcomingContainer style={{width: "98%"}}>
          {!dashboardLoading && (
            <>
              {dashboardData?.dueOrders?.length > 0 && (
                <ChartOrder>
                    <TableLabelStyle>Due Orders</TableLabelStyle>
                    <Table
                      stickyHeader
                      style={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        width: "98%",
                        marginTop: "10px",
                        marginBottom: "20px",
                        marginLeft: "10px"
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          {orderHeader["Due Orders"]?.map(
                            (item: string, index: number) => (
                              <StyledTableHeadCell key={index} align="center">
                                {item}
                              </StyledTableHeadCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashboardData?.dueOrders?.map(
                          (item: any, index: number) => (
                            <TableRow hover>
                              {/* <TableCell
                                align="center"
                                style={{ cursor: "pointer" }}
                                onClick={() => navToEdit(item)}
                              >
                                {item.orderId}
                              </TableCell> */}
                              <TableCell align="center">
                                {item.customerName}
                              </TableCell>
                              <TableCell align="center">
                                {item.customerNumber}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                {Math.round(item.finalAmount)}
                              </TableCell>
                              {/* <TableCell align="center">
                                {" "}
                                {Math.round(item.advancePayment)}
                              </TableCell> */}
                              <TableCell align="center">
                                {Math.round(
                                  item.finalAmount - item.advancePayment
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {dayjs(item?.deliveryDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: StatusLabelColor(item.status),
                                  fontWeight: "bold",
                                }}
                              >
                                {item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                </ChartOrder>
              )}
            </>
          )}
          </StyledUpcomingContainer>
      </WrapperItem>
    </Container>
  );
};

export default DefaultLayout;
